<template>
    <div v-if="toolLevels[toolId].children.length > 0" class="button-bar">
        <b-button
            class="submenu-tool-button"
            v-for="buttonToolID in toolLevels[toolId].children"
            :key="buttonToolID"
            @click="handleButtonClick(buttonToolID)"
        >
            <!-- <img
                v-else
                class="pb-1 submenu-tool-icon"
                :src="toolIconPath(toolLevels[buttonToolID].ToolName)"
            /> -->
            {{ toolLevels[buttonToolID].ToolName }}
            <img
                v-if="toolLevels[buttonToolID].children.length"
                class="pb-1 submenu-arrow-icon"
                :class="showNextMenus ? '' : 'rotate90cc'"
                :src="require('../../../assets/chevron-down-icon.svg')"
        /></b-button>
        <sub-menus
            v-if="showNextMenus"
            :toolId="nextToolID"
            @showTool="showTool"
        ></sub-menus>
    </div>
</template>

<script>
import { mapState } from 'vuex';

import { toolIconPath } from '../../../utilities/oneMapTools.js';

export default {
    name: 'SubMenus',
    components: {},
    props: {
        toolId: {
            type: Number,
            default: 0,
            required: false,
        },
    },
    data() {
        return {
            nextToolID: 0,
            showNextMenus: false,
        };
    },
    methods: {
        toolIconPath,
        clearNextMenus() {
            this.nextToolID = 0;
            this.showNextMenus = false;
        },
        handleButtonClick(toolID) {
            if (this.nextToolID == toolID) {
                this.clearNextMenus();
            } else if (this.toolLevels[toolID].children.length) {
                this.nextToolID = toolID;
                this.showNextMenus = true;
            } else {
                this.clearNextMenus();
                this.$emit('showTool', this.toolLevels[toolID].ToolName);
            }
        },
        showTool(newToolID) {
            this.$emit('showTool', newToolID);
        },
    },
    computed: {
        ...mapState('oneMap', ['toolLevels']),
    },
};
</script>

<style scoped>
.submenu-tool-button {
    height: 40px;
    color: black;
    border-width: 0;
    border-radius: 0;
    background-color: white;
    border-bottom-color: #00496d !important;
    border-bottom-width: 5px;
    margin-left: 5px;
}
.submenu-tool-icon {
    height: 26px;
    width: 26px;
}
.submenu-arrow-icon {
    transform: scale(1);
}
.rotate90cc {
    padding-left: 2px;
    transform: rotate(-90deg) scale(1);
}
</style>
